body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Work Sans',
    sans-serif,;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input-new{
  width: 480px;
  height: 55px;
  border: 1px solid #AAAAAA;
  color: #AAAAAA;
  padding-left: 5%;
  font-size: 20px;
}
.input-city{
  width: 50%;
  height: 55px;
  border: 1px solid #AAAAAA;
  color: #AAAAAA;
  padding-left: 5%;
  font-size: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .input-city {
  height: 47px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 768px) {
  .input-city {
  height: 47px;
  }
}@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .input-city {
  height: 57px;
  }
}@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .input-city {
  height: 57px;
  }
}
.input-clock{
  width: 80%;
  height: 55px;
  border: 1px solid #AAAAAA;
  color: #AAAAAA;
  padding-left: 5%;
  font-size: 19px;
}
.input-question{
  
  border: 1px solid #AAAAAA;
  color: #AAAAAA;
  padding-left: 5%;
  font-size: 20px;
}

.textured-background {
  position: relative;
}

.textured-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/BgPng2.jpeg");
  mix-blend-mode: overlay; /* Adjust blend mode as needed */
  opacity: 0.7; /* Adjust opacity of the texture */
}

.text-stroke{
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #F69F7F;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
